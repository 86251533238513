<template>
  <div>
    <div class="va-row">
      <div class="flex md12 xs12">
        <vuestic-data-table
          class="text-center"
          :apiMode="true"
          :apiUrl="apiUrl"
          :httpOptions="httpOptions"
          :defaultPerPage="defaultTablePerPage"
          :tableFields="translatedTableFields"
          :perPageSelectorShown="false"
          :actions="true"
          :additionalActions="true"
          :filterInputShown="false"
          :newPagination="true"
        >
          <template slot="additionalTableControl">
            <div class="form-group">
              <date-picker
                :value="dateRange"
                :config="datePickerOptions"
                @rangeChange="dateRange = $event"
              />
              <label class="control-label" for="simple-input-2">{{
                $t('billing.charges.filters.select-period')
              }}</label>
            </div>
          </template>
        </vuestic-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DatePicker from '../../elements/DatePicker.vue'
import { mapGetters } from 'vuex'
import momentTimezone from 'moment-timezone'

const moment = require('moment')
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      dateRange: {
        from: moment()
          .subtract('30', 'days')
          .format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      datePickerOptions: {
        mode: 'range',
        maxDate: 'today',
        maxRange: 90
      },
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'ngrok-skip-browser-warning': true,
          organization: localStorage.getItem('organizationId'),
          'Accept-Language': localStorage.getItem('locale'),
          timezone: momentTimezone.tz.guess()
        }
      },
      tableFields: [
        {
          name: '__sequence',
          title: '#',
          dataClass: 'text-light'
        },
        {
          name: 'date',
          title: 'billing.charges.table.date'
        },
        {
          name: 'description',
          title: 'billing.charges.table.description'
        },
        {
          name: 'amount',
          title: 'billing.charges.table.amount'
        },
        {
          name: 'currency',
          title: 'billing.charges.table.currency'
        }
      ],
      defaultTablePerPage: 10
    }
  },
  watch: {
    currentTenant() {
      const organizationId = localStorage.getItem('organizationId')
      this.httpOptions.headers.organization = organizationId
    }
  },
  computed: {
    ...mapGetters(['currentTenant', 'currentJera']),
    apiUrl() {
      return `${process.env.VUE_APP_NEW_API}jera/charge-list?fromDate=${
        this.dateRange.from
      }&toDate=${this.dateRange.to || this.dateRange.from}&itemsPerPage=${
        this.defaultTablePerPage
      }`
    },
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          title: this.$t(field.title)
        }
      })
    },
    locale() {
      return Vue.i18n.locale()
    }
  }
}
</script>
<style lang="scss" scoped>
.form-group {
  min-width: 18rem;
}
</style>
